<template>
    <div id="fh5co-about" class="animate-box">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
                    <h2>About Me</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <ul class="info">
                        <li><span class="first-block">Full Name:</span><span class="second-block">Desta Wiji Pratama</span></li>
                        <li><span class="first-block">Website:</span><span class="second-block">www.destawp.com</span></li>
                        <li><span class="first-block">Address:</span><span class="second-block">Indonesia</span></li>
                    </ul>
                </div>
                <div class="col-md-8">
                    <h2>Hello There!</h2>
                    <p>
                        I graduated from Information System of Stikubank University. When I was in college, I learned about website development, mobile application development, network security, data mining, management, and many more.

                    </p>

                    <p>
                        However, my heart says that go to websites development is better for me. There, I enjoy doing my job more. So, I learned all about website development to date.
                    </p>
                    <p>
                        <ul class="fh5co-social-icons" style="margin-left: 10px">
                            <li><a href="https://www.linkedin.com/in/desta-wiji-pratama/" style="margin-right: 10px"><i class="icon-linkedin2"></i></a></li>
                            <li><a href="https://destawp.com"><i class="icon-dribbble2"></i></a></li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AboutSection'
}
</script>