<template>
  <div>
    <div id="fh5co-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <p>&copy; {{ year }} | Made With 💕</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'footerSection',
  data() {
    return {
      year: null,
    };
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
};
</script>
