import Vue from 'vue';
import VueRouter from 'vue-router';
const Main = () => import('../views/Main.vue');
// const NotFound = () => import('../views/Page404.vue');

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'homeSection',
    component: Main,
  },
  {
    path: '*',
    name: 'notFound',
    component: Main,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
