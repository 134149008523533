<template>
  <div id="fh5co-resume" class="fh5co-bg-color">
    <div class="container">
      <div class="row animate-box">
        <div class="col-md-8 col-md-offset-2 text-center fh5co-heading">
          <h2>My Resume</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-md-offset-0">
          <ul class="timeline">
            <li class="timeline-heading text-center animate-box">
              <div><h3>Work Experience</h3></div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge"><i class="icon-suitcase"></i></div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3 class="timeline-title">Developer</h3>
                  <span class="company">PT Bank SMBC Indonesia</span>
                  <span class="company">2024 - Current</span>
                </div>
                <div class="timeline-body">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="animate-box timeline-unverted">
              <div class="timeline-badge"><i class="icon-suitcase"></i></div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3 class="timeline-title">Backend Engineer</h3>
                  <span class="company">KOMPAS GRAMEDIA</span>
                  <span class="company">2022 - 2024</span>
                </div>
                <div class="timeline-body">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge"><i class="icon-suitcase"></i></div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3 class="timeline-title">Web Developer</h3>
                  <span class="company">PT Indikom Megah Agung</span>
                  <span class="company">2021 - 2022</span>
                </div>
                <div class="timeline-body">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="animate-box timeline-unverted">
              <div class="timeline-badge"><i class="icon-suitcase"></i></div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3 class="timeline-title">Technical Support</h3>
                  <span class="company">PT Telekomunikasi Selular</span>
                  <span class="company">2020 - 2021</span>
                </div>
                <div class="timeline-body">
                  <p></p>
                </div>
              </div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge"><i class="icon-suitcase"></i></div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3 class="timeline-title">Warehouse Administrator</h3>
                  <span class="company">PT Mandala Adhiperkasa Sejati</span>
                  <span class="company">2018 - 2020</span>
                </div>
                <div class="timeline-body">
                  <p></p>
                </div>
              </div>
            </li>

            <br />
            <li class="timeline-heading text-center animate-box">
              <div><h3>Education</h3></div>
            </li>
            <li class="timeline-inverted animate-box">
              <div class="timeline-badge">
                <i class="icon-graduation-cap"></i>
              </div>
              <div class="timeline-panel">
                <div class="timeline-heading">
                  <h3 class="timeline-title">Bachelors Degree</h3>
                  <span class="company">Stikubank University</span>
                  <span class="company">2016 - 2020</span>
                </div>
                <div class="timeline-body"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ResumeSection',
};
</script>
