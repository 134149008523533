<template>
  <div>
    <Header />
    <About />
    <Resume />
    <Portfolio />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/template/Header.vue';
import Footer from '../components/template/Footer.vue';
import About from '../components/About.vue';
import Resume from '../components/Resume.vue';
import Portfolio from '../components/Portfolio.vue';
export default {
  name: 'mainSection',
  components: {
    Header,
    Footer,
    About,
    Resume,
    Portfolio,
  },
};
</script>
