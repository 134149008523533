<template>
    <header id="fh5co-header" class="fh5co-cover js-fullheight" role="banner" style="background-image:url(/assets/images/cover_bg_3.jpg);" data-stellar-background-ratio="0.5">
		<div class="overlay"></div>
		<div class="container">
			<div class="row">
				<div class="col-md-8 col-md-offset-2 text-center">
					<div class="display-t js-fullheight">
						<div class="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
							<div class="profile-thumb" style="background: url(/assets/images/Desta.png);"></div>
							<h1><span>Desta Wiji Pratama</span></h1>
							<h3><span>Backend Engineer</span></h3>
							<br>
							<p>
								<ul class="fh5co-social-icons">
									<!-- <li><a href="#"><i class="icon-twitter2"></i></a></li>
									<li><a href="#"><i class="icon-facebook2"></i></a></li> -->
									<li><a href="https://www.linkedin.com/in/desta-wiji-pratama/"><i class="icon-linkedin2"></i></a></li>
								</ul>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>
<script>
export default {
    name: 'headerSection'
}
</script>