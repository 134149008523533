<template>
  <Main></Main>
</template>

<script>
import Main from './views/Main.vue';

export default {
  name: 'App',
  components: {
    Main,
  },
};
</script>
